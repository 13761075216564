export const tableOption = {
  searchMenuSpan: 6,
  columnBtn: false,
  border: true,
  selection: true,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menuAlign: 'center',
  menuWidth: 350,
  align: 'center',
  refreshBtn: true,
  searchSize: 'mini',
  addBtn: false,
  editBtn: false,
  delBtn: false,
  viewBtn: false,
  props: {
    label: 'label',
    value: 'value'
  },
  column: [{
    label: '图片',
    prop: 'roomImgUrl',
    type: 'upload',
    slot: true,
    listType: 'picture-img'
  }, {
    label: '园区编号',
    prop: 'areaId'
  }, {
    label: '会议室编号',
    prop: 'roomId'
  }, {
    label: '会议室名称',
    prop: 'roomName',
    search: true
  }, {
    label: '地址',
    prop: 'address'
  }, {
    label: '顺序',
    prop: 'roomIndex'
  }, {
    label: '容纳人数',
    prop: 'memberNum'
  }, {
    label: '面积',
    prop: 'squareMeasure'
  }, {
    label: '开放时间',
    prop: 'openTime',
    type: 'select',
    dicData: [
      {
        label: '工作时间',
        value: 0
      }, {
        label: '全天',
        value: 1
      }
    ]
  }, {
    label: '属性',
    prop: 'roomType',
    type: 'select',
    dicData: [
      {
        label: '接待',
        value: 0
      }, {
        label: '研讨',
        value: 1
      }
    ]
  }, {
    label: '用途',
    prop: 'roomUse',
    type: 'select',
    dicData: [
      {
        label: '商务接待',
        value: 0
      }, {
        label: '商务会务',
        value: 1
      }
    ]
  }, {
    label: '会议室设备',
    prop: 'roomEqList'
  }, {
    label: '增值服务',
    prop: 'roomValuesServices'
  }, {
    width: 150,
    label: '状态',
    prop: 'status',
    search: true,
    type: 'select',
    dicData: [
      {
        label: '待发布',
        value: 0
      }, {
        label: '发布',
        value: 1
      }
    ]
  }, {
    label: '备注',
    prop: 'remark'
  }]
}
